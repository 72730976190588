
function FormatTimeAgo(milliseconds)
{

    function numberEnding (number) {
        return (number > 1) ? 's' : '';
    }

    var temp = Math.floor(milliseconds / 1000);
    var years = Math.floor(temp / 31536000);

    // Years ago
    if (years) {
        return years + ' year' + numberEnding(years);
    }
    
    // Days ago
    var days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
        return days + ' day' + numberEnding(days);
    }

    // Hours ago
    var hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
        return hours + ' hour' + numberEnding(hours);
    }

    // Minutes ago
    var minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
        return minutes + ' minute' + numberEnding(minutes);
    }

    // Seconds ago
    var seconds = temp % 60;
    if (seconds) {
        return seconds + ' second' + numberEnding(seconds);
    }

    return 'now';
}

function diffDateInMilliseconds(date1, date2)
{
    var time1 = new Date(date1).getTime();
    var time2 = new Date(date2).getTime();
    // Production database is in UTC time.
    // console.log("databaseTime", time1, " ", new Date(time1).toString());
    // console.log("now", time2, " ", new Date(time2).toString())
    
    // var SubtractDates = Math.floor(time2 - time1 ) ;
    var SubtractDates = time2 - time1  ;
    // console.log("SubtractDates",SubtractDates)

    return SubtractDates;
}

function timeAgo(databaseTime) {
  var now = new Date();
  return FormatTimeAgo(diffDateInMilliseconds(databaseTime, now));
}

export default timeAgo;
